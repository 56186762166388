<script>
import { api } from "@/state/services";

export default {
  props: {
    modal: {
      type: Boolean,
      default: false
    },
  },
  components: {
  },
  data() {
    return {
      loading: false,
      errored: '',

      box: null
    };
  },
  methods: {
    getBox() {
      api
        .get("user/box")
        .then((res) => {
          if (res.data.status == "success") {
            this.box = res.data.box;
          }
        })
    },
  },
  mounted() {
    this.getBox();
  },
};
</script>

<template>
  <div>
    <b-modal v-model="modal" modal-class="modal-right" hide-header hide-footer>
      <div v-if="box">
        <h4 class="mb-5">Meu Box</h4>
        <div class="d-flex justify-content-between mb-1">
          <div>
            Endereço
          </div>
          <div class="d-flex align-items-center">
            <div class="mr-2">{{ box.address }}, {{ box.number }}</div>
            <div>
              <button class="btn btn-link p-0 m-0" :v-clipboard:copy="box.address + ', ' + box.number" v-on:click="$toast.success('Copiado!')">
                <i class="bx bx-copy text-dark font-size-18"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-1">
          <div>
            Complemento
          </div>
          <div class="d-flex align-items-center">
            <div class="mr-2">{{ box.complement }}</div>
            <div>
              <button class="btn btn-link p-0 m-0" v-clipboard:copy="box.complement" v-on:click="$toast.success('Copiado!')">
                <i class="bx bx-copy text-dark font-size-18"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-1">
          <div>
            Bairro
          </div>
          <div class="d-flex align-items-center">
            <div class="mr-2">{{ box.district }}</div>
            <div>
              <button class="btn btn-link p-0 m-0" v-clipboard:copy="box.district" v-on:click="$toast.success('Copiado!')">
                <i class="bx bx-copy text-dark font-size-18"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-1">
          <div>
            Bairro
          </div>
          <div class="d-flex align-items-center">
            <div class="mr-2">{{ box.city }}/{{ box.state }}</div>
            <div>
              <button class="btn btn-link p-0 m-0" :v-clipboard:copy="box.city + '/' + box.state" v-on:click="$toast.success('Copiado!')">
                <i class="bx bx-copy text-dark font-size-18"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between mb-1">
          <div>
            CEP
          </div>
          <div class="d-flex align-items-center">
            <div class="mr-2">{{ box.zipcode }}</div>
            <div>
              <button class="btn btn-link p-0 m-0" v-clipboard:copy="box.zipcode" v-on:click="$toast.success('Copiado!')">
                <i class="bx bx-copy text-dark font-size-18"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between mt-5">
        <a class="btn btn-outline-link" v-on:click="modal = false;">Fechar <sup>ESC</sup></a>
      </div>
    </b-modal>
  </div>
</template>