<script>
import { mapState } from "vuex";
import Languages from "@/components/widgets/languages";
import Box from "@/components/widgets/user-box";

export default {
  locales: {
    pt: {
      Settings: "Configurações",
      "My Account": "Minha Conta",
      "My Address": "Meus Endereços",
      "Bank Account": "Conta Bancária",
      "My Password": "Minha Senha",
      "Password Security": "Senha de Segurança",
      Logout: "Sair",
    },
    es: {
      Settings: "Configuraciones",
      "My Account": "Mi Cuenta",
      "My Address": "Mi Endereços",
      "Bank Account": "Cuenta Bancaria",
      "My Password": "Mi Contraseña",
      "Password Security": "Contraseña de Seguridad",
      Logout: "Cerrar Sesión",
    },
  },
  components: {
    Languages,
    Box
  },
  props: {
    user: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isCondensed: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      avatar: this.$store.state.account.user.avatar,

      box: false,
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  created() {},
  methods: {
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    showBox() {
      this.box = false;
      this.box = true;
    },
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <button id="vertical-menu-btn" type="button" class="btn btn-sm px-3 font-size-20 header-item" @click="toggleMenu">
          <i class="fa fa-fw fa-bars"></i>
        </button>
      </div>

      <div class="d-flex">
        <router-link tag="a" to="/" class="logo">
          <img style="height: 40px" src="@/assets/images/logo-header.png" />
        </router-link>
      </div>

      <div class="d-flex align-items-center">
        <Languages class="d-none"></Languages>

        <button class="border-0 bg-transparent" v-on:click="showBox()">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path
              d="M21.993 7.95a.96.96 0 0 0-.029-.214c-.007-.025-.021-.049-.03-.074-.021-.057-.04-.113-.07-.165-.016-.027-.038-.049-.057-.075-.032-.045-.063-.091-.102-.13-.023-.022-.053-.04-.078-.061-.039-.032-.075-.067-.12-.094-.004-.003-.009-.003-.014-.006l-.008-.006-8.979-4.99a1.002 1.002 0 0 0-.97-.001l-9.021 4.99c-.003.003-.006.007-.011.01l-.01.004c-.035.02-.061.049-.094.073-.036.027-.074.051-.106.082-.03.031-.053.067-.079.102-.027.035-.057.066-.079.104-.026.043-.04.092-.059.139-.014.033-.032.064-.041.1a.975.975 0 0 0-.029.21c-.001.017-.007.032-.007.05V16c0 .363.197.698.515.874l8.978 4.987.001.001.002.001.02.011c.043.024.09.037.135.054.032.013.063.03.097.039a1.013 1.013 0 0 0 .506 0c.033-.009.064-.026.097-.039.045-.017.092-.029.135-.054l.02-.011.002-.001.001-.001 8.978-4.987c.316-.176.513-.511.513-.874V7.998c0-.017-.006-.031-.007-.048zm-10.021 3.922L5.058 8.005 7.82 6.477l6.834 3.905-2.682 1.49zm.048-7.719L18.941 8l-2.244 1.247-6.83-3.903 2.153-1.191zM13 19.301l.002-5.679L16 11.944V15l2-1v-3.175l2-1.119v5.705l-7 3.89z">
            </path>
          </svg>
        </button>

        <Box :modal="box"></Box>

        <b-dropdown right variant="black" toggle-class="header-item p-2">
          <template v-slot:button-content>
            <img v-if="account.user.avatar" class="rounded-circle header-profile-user" :src="account.user.avatar" alt="" />
            <img v-else class="rounded-circle header-profile-user" :src="
                'https://ui-avatars.com/api/?background=0C4CBC&color=fff&name=' +
                account.user.name
              " alt="" />
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <router-link tag="a" to="/configuracoes" class="dropdown-item">
            <span>{{ t("My Account") }}</span>
          </router-link>
          <router-link tag="a" to="/configuracoes/assinatura" class="dropdown-item">
            <span>{{ t('Minha Assinatura') }}</span>
          </router-link>
          <router-link tag="a" to="/configuracoes/alterar-senha" class="dropdown-item">
            <span>{{ t("My Password") }}</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <router-link tag="a" to="/logout" class="dropdown-item mb-2">
            <span>{{ t("Logout") }}</span>
          </router-link>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
